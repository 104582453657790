import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Prevent from "./components/Preevent"
import {
  BrowserRouter as Router 
} from "react-router-dom"
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      {/* <Prevent /> */}
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals()
