import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute({ comp: Component, ...restOfProps }) {
  const isAuthenticated = localStorage.getItem("token");
  console.log("Checking...: ", isAuthenticated )
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated != null ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default ProtectedRoute;