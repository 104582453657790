// import {useState} from 'react'
import './App.css'
import ValentinoBeauty from "./components/ValentinoBeauty"
import Landing from "./components/Landing"
import ProtectedRoute from "./components/ProtectedRoute"
import UnprotectedRoute from "./components/UnprotectedRoute"
import {
  Switch
} from "react-router-dom"
import Plausible from 'plausible-tracker'
 
const plausible = Plausible({
  domain: process.env.ANALYTICS_DOMAIN
})

plausible.enableAutoPageviews()
plausible.enableAutoOutboundTracking()


function App() {
  return (
      <Switch>
        <ProtectedRoute path="/landing" comp={Landing}>
        </ProtectedRoute>
        <UnprotectedRoute path="/" comp={ValentinoBeauty}>
        </UnprotectedRoute>
      </Switch>    
  );
}

export default App;
