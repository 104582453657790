// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { PlasmicLanding } from "./plasmic/valentino/PlasmicLanding";
import useInterval from '../hooks/useInterval'
import Plausible from 'plausible-tracker'

const { trackEvent } = Plausible()

function Landing_(props, ref) {
  window.FreshworksWidget('hide')
  const [showBreakouts, setShowBreakouts] = useState(0)
  function canIGoNow() {
    axios.get("https://valentino.thumbwar.workers.dev/canigonow")
    .then((response) => {
      console.log(response)
      setShowBreakouts(parseInt(response.data.status))
    })
    .catch((e) => {
      console.log(e)
    })
  }
  useInterval(() => {
    canIGoNow()
  },8000)
  useEffect(() => {
    canIGoNow()
  }, [])
  return <PlasmicLanding
  chatroll={{
    props: {
      children: <iframe title="Chatroll" width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" allowtransparency="true" src="https://chatroll.com/embed/chat/join-the-couture-chat?id=83YTEqOqX7w&platform=html"></iframe>
    }
  }}
  carousel={{
    props: {
      children: 
        <iframe
          src="https://plugins.flockler.com/embed/iframe/179f2240ce709e99b5f2b97e72dd7707/17a025b901e0fdfd16610ab7333f62b8"
          id="flockler-embed-iframe-17a025b901e0fdfd16610ab7333f62b8"
          height="100%" 
          width="100%"
          style={{display:"block", border:"none"}}>
        </iframe>
    }
  }}
  videoBox={{
    props: {
      children: 
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/q1P3DNs9H_Y" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    }
  }}
  firstBreakout={{
    props: {style:{visibility: showBreakouts ? "visible" : "hidden"}}
  }}
  secondBreakout={{
    props: {
      style:{
        visibility: showBreakouts ? "visible" : "hidden",
        cursor:"pointer"
      },
      onClick: (e) => {
        trackEvent("zoom_click", {"props": {"user":localStorage.getItem("userID")}})
        window.open("https://zoom.us/j/95717722167", "_blank")
      }
    }
  }}
  photoboothBox={{
    props: {
      style:{
        cursor:"pointer"
      }
    },
    wrap: (content) =>
      <div onClick={(e) => {
        trackEvent("photobooth_click", {"props": {"user":localStorage.getItem("userID")}})
        window.open("https://www.instagram.com/valentino.beauty/?hl=en", "_blank")
        }}>
        {content}
      </div>
  }}
  chatBox={{
    props: {style:{boxSizing: "border-box", "overflow":"hidden"}}
  }}
  root={{ ref }}
  disclaimer={{
    props: {
      children: <React.Fragment>By using Chat you agree your information will be collected and used subject to our <a href=" https://www.valentino-beauty.us/privacy-policy.html" target="_blank">privacy policy</a> and <a href="https://www.valentino-beauty.us/customer-service-terms-and-conditions.html" target="_blank">terms of use</a>.  Please do not share personal information such as credit card numbers or health concerns with our Bot.</React.Fragment>
    }
  }}
  photoboothGif={{
    props: {src:"photobooth.gif"}
  }}
  
  {...props} />;
}

const Landing = React.forwardRef(Landing_);

export default Landing;
