// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react"
import { PlasmicValentinoBeauty } from "./plasmic/valentino/PlasmicValentinoBeauty"
import { useForm, Controller } from "react-hook-form"
import axios from 'axios'
import { useHistory } from "react-router-dom"
import Plausible from 'plausible-tracker'
const { trackEvent } = Plausible()

axios.defaults.baseURL = process.env.BASE_URL

function ValentinoBeauty_(props, ref) {
  window.FreshworksWidget('show')
  const { control, handleSubmit } = useForm()
  const history = useHistory()
  const onSubmit = data => {
    console.log(data)
    axios.post('/api/login', data)
    .then(function (response) {
      localStorage.setItem("token", response.data.token)
      localStorage.setItem("userID", response.data.userID)
      trackEvent('login', {"props": {"user":localStorage.getItem("userID")}})
      history.push("/landing")
    })
    .catch(function (error) {
      alert("Invalid first or last name.")
      console.log(error)
    });
    
  }
  return <form onSubmit={handleSubmit(onSubmit)}>
    <PlasmicValentinoBeauty root={{ ref }} {...props} 
      firstNameTextbox={{
        wrap:(content) => {
          return <Controller
            name="firstName"
            defaultValue=""
            control={control}
            rules={{required:true}}
            render={({ field }) => {
              var el = React.cloneElement(
                content, 
                {...field}
              )
              return el
            }}
          />
        } 
      }}
      lastNameTextbox={{
        wrap:(content) => {
          return <Controller
            name="lastName"
            defaultValue=""
            control={control}
            rules={{required:true}}
            render={({ field }) => {
              var el = React.cloneElement(
                content, 
                {...field}
              )
              return el
            }}
          />
        }
      }}
      emailTextbox={{
        props: {type:"email"},
        wrap:(content) => {
          return <Controller
            name="email"
            defaultValue=""
            control={control}
            rules={{required:true}}
            render={({ field }) => {
              var el = React.cloneElement(
                content, 
                {...field}
              )
              return el
            }}
          />
        }
      }}
      loginButton={{
        props: {
          type:"submit",
          style:{
            cursor:"pointer"
          }
        }
      }}
    />
    
  </form>
}

const ValentinoBeauty = React.forwardRef(ValentinoBeauty_)

export default ValentinoBeauty
